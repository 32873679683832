import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/City/cityActionType";
import * as actions from "../../Action/City/getZoneAction";
import NetworkService from "../../../network/Network/City/cityNetwork";

export function* getZone(action) {
  try {
    let response = yield call(NetworkService.getZone, action.payload);
    
    yield put(actions.getZoneSuccess(response));
  } catch (err) {
    yield put(actions.getZoneFailure(err));
  }
}

export function* watchGetZone() {
  yield takeLatest(types.GET_ZONE_REQUEST, getZone);
}