import RequestService from "../../RequestService";
import { api } from "../../../helpers/City/cityConstant";

class NetworkService {
  viewAllCity() {
    const endPoint = `${api.viewAllCity}`;
    return RequestService.getRequest(endPoint);
  }

  getCity(action) {
    const endPoint = `${api.getCity}?latitude=${action.lat}&longitude=${action.long}&useFreeApi=true`;
    return RequestService.getRequest(endPoint);
  }

  getZone(action) {
    const endPoint = `${api.getZone}/${action.cityId}`;
    return RequestService.getRequest(endPoint);
  }
}

export default new NetworkService();
