import { all } from "redux-saga/effects";
import { watchViewAllCity } from "./viewAllCitySaga";
import { watchGetCity } from "./getCitySaga";
import { watchGetZone } from "./getZoneSaga";

function* rootCitySaga() {
  yield all([watchViewAllCity(), watchGetCity(),watchGetZone()]);
}

export default rootCitySaga;
