import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Landing/landingActionType";
import * as actions from "../../Action/Landing/getPopularPlanAction";
import NetworkService from "../../../network/Network/Landing/landingNetwork";

export function* getPopularPlanSaga(action) {

  try {
    if(isNaN(action.data.cityId)){
      action.data.cityId = 0;
    }
    let response = yield call(NetworkService.getPopularPlan, action);
    if(response.data.data.length > 0){
    yield put(actions.getPopularPlanSuccess(response));      
    } else{
      action.data.cityId = 0;
    response = yield call(NetworkService.getPopularPlan, action);
        yield put(actions.getPopularPlanSuccess(response));      
    }
  } catch (err) {
    yield put(actions.getPopularPlanFailure(err));
  }
}

export function* watchGetPopularPlanSaga() {
  yield takeLatest(types.GET_POPULAR_PLAN_REQUEST, getPopularPlanSaga);
}