export const VIEW_ALL_CITY_REQUEST = "VIEW_ALL_CITY_REQUEST"
export const VIEW_ALL_CITY_SUCCESS = "VIEW_ALL_CITY_SUCCESS"
export const VIEW_ALL_CITY_FAILURE = "VIEW_ALL_CITY_FAILURE"

export const GET_CITY_REQUEST = "GET_CITY_REQUEST"
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS"
export const GET_CITY_FAILURE = "GET_CITY_FAILURE"

export const GET_ZONE_REQUEST = "GET_ZONE_REQUEST"
export const GET_ZONE_SUCCESS = "GET_ZONE_SUCCESS"
export const GET_ZONE_FAILURE = "GET_ZONE_FAILURE"
