import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import checkmark from "../../../assets/Checkmark.png";
import { getPopularPlanHomeScreenRequest } from "../../../redux/Action/Landing/getPopularPlanHomeScreenAction";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import Title from "../../Atoms/Title/Title";
import createSlug from "../../Molecules/SlugGenerator/CreateSlug";
import storage from "../../../Utils/storage";
import "./OurPlan.css";
import "./PopularPlans.css";

function PopularPlans() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getPopularPlanHomeScreen = useSelector(
    (state) => state.landing.getPopularPlanHomeScreen
  );
  let cityId = parseInt(storage.getStorage("cityId"));

  useEffect(() => {
    dispatch(getPopularPlanHomeScreenRequest({cityId:cityId}));
  }, [dispatch]);

  const handleBuyNow = async (index) => {
    try {
      const selectedItem =
        getPopularPlanHomeScreen?.data?.data?.data?.data[index];
      const slug = await createSlug(selectedItem.name);

      navigate(`/plan/${slug}?id=${selectedItem.planUuid}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {
      console.error("Error creating slug or navigating:", error);
    }
  };

  if (getPopularPlanHomeScreen.isLoading || !getPopularPlanHomeScreen.data) {
    return <YuvaLoader />;
  }

  return (
    <>
      <Title title="YUVA | Comprehensive packages at a fraction of the cost." />
      <div className="PopularPlansMain">
        {getPopularPlanHomeScreen?.data?.data?.data?.data?.map(
          (item, index) => (
            <div className="PopularPlansMainIndividual" key={index}>
              <div className="PopularPlansMainIndividualTop">
                <p className="PopularPlansMainIndividualHeading">{item.name}</p>
              </div>
              <p className="PopularPlansMainIndividualSubHeading">
                Check Health Benefits{" "}
              </p>
              {item.price != item.discountedPrice ? (
                <div className="PopularPlansMainPrice">
                  <p className="PopularPlansMainFinalPrice">
                    ₹ {item.discountedPrice}
                  </p>
                  <p className="PopularPlansMainIndividualPrice">
                    ₹ {item.price}
                  </p>
                </div>
              ) : (
                <p className="PopularPlansMainFinalPrice" style={{color: 'black'}}>₹ {item.price}</p>
              )}

              <button
                className="PopularPlansMainIndividualBuyButton"
                onClick={() => handleBuyNow(index)}
              >
                Buy Now
              </button>
              <div className="PopularPlansMainLower">
                <p className="PopularPlansMainLowerHeading">Key Features</p>
                {item.planServiceNameList?.map((feature, idx) => (
                  <div className="PopularPlansMainLowerRows" key={idx}>
                    <div className="PopularPlansMainLowerRowsCol1">
                      <img src={checkmark} alt="checkmark" />
                    </div>
                    <div className="PopularPlansMainLowerRowsCol2">
                      {feature.serviceName}
                    </div>
                    <div className="PopularPlansMainLowerRowsCol3">
                      {feature.shortDescription}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
}

export default PopularPlans;
