import CryptoJS from "crypto-js";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../Utils/storage";
import { requestPermission } from "../../../firebase";
import { getCartRequest } from "../../../redux/Action/Cart/GetCartAction";
import { getCityRequest } from "../../../redux/Action/City/getCityAction";
import { viewAllCityRequest } from "../../../redux/Action/City/viewAllCityAction";
import { viewHomeScreenProductRequest } from "../../../redux/Action/EcomUser/viewHomeScreenProductAction";
import { elasticSearchRequest } from "../../../redux/Action/ElasticSearch/ElasticSearchAction";
import { getPopularPackageRequest } from "../../../redux/Action/Landing/getPopularPackageAction";
import { getPopularPlanRequest } from "../../../redux/Action/Landing/getPopularPlanAction";
import { getPopularTestRequest } from "../../../redux/Action/Landing/getPopularTestAction";
import { deviceTokenRequest } from "../../../redux/Action/Token/DeviceTokenAction";
import { logoutUserRequest } from "../../../redux/Action/User/logoutUserAction";
import { getPopularPlanHomeScreenRequest } from "../../../redux/Action/Landing/getPopularPlanHomeScreenAction";
import {getZoneRequest} from '../../../redux/Action/City/getZoneAction';
import "./Navbar.css";
import NavbarMobile from "./NavbarMobile";
import NavbarWeb from "./NavbarWeb";

export default function Navbar() {
  const [cartIsEmpty, setCartIsEmpty] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openmodal, setopenModal] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [fcmToken, setFcmtoken] = useState();
  const [ans, setAns] = useState(false);

  const name = storage.getStorage("name");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const viewCityNames = useSelector((state) => state.city.viewAllCity);
  const createOrder = useSelector((state) => state.cart.createOrder);
  const getCity = useSelector((state) => state.city.getCity);
  const getZone = useSelector((state) => state?.city?.getZone?.data);

  const homeScreenProduct = useSelector(
    (state) => state.eCom.viewHomeScreenProduct
  );

  const dispatch = useDispatch();

  const [badgeCount, setBadgeCount] = useState();
  const [cityId, setCityId] = useState(storage.getStorage("cityId"));

  const modalRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (
      (storage.getStorage("cityId") === null ||
        storage.getStorage("cityId") === undefined) &&
      getCity.data !== null
    ) {
      storage.setStorage("cityId", getCity.data?.data.data.cityId);
      // storage.setStorage("cityName", getCity.data?.data.data.cityName);
      setCityId(getCity.data?.data.data.cityId);
      dispatch(getZoneRequest(getCity.data?.data.data.cityId));
    }
  }, [getCity]);

  useEffect(() => {
    dispatch(getZoneRequest(cityId))
  },[cityId])

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    window.addEventListener("popstate", handleNavigationPopState);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("popstate", handleNavigationPopState);
    };
  }, []);

  useEffect(() => {
    const location = window.navigator && window.navigator.geolocation;
    dispatch(viewAllCityRequest());
    if (
      location &&
      (storage.getStorage("cityId") === null ||
        storage.getStorage("cityId") === undefined)
    ) {
      location.getCurrentPosition(
        (position) => {
          dispatch(
            getCityRequest(position.coords.latitude, position.coords.longitude)
          );
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [dispatch]);

  useEffect(() => {
    const handleBadge = () => {
      setBadgeCount(
        localStorage.getItem("itemDtoList") !== null
          ? JSON.parse(localStorage.getItem("itemDtoList")).length
          : undefined
      );
    };
    handleBadge();
    window.addEventListener("storage", handleBadge);
    return () => {
      window.removeEventListener("storage", handleBadge);
    };
  }, []);

  useEffect(() => {
    if (
      createOrder.data?.data.status === 200 &&
      createOrder.data?.data.data.data.amountZero === true
    ) {
      setBadgeCount(undefined);
    }
  }, [createOrder]);

  useEffect(() => {
    dispatch(getCartRequest());
  }, [dispatch]);

  useEffect(() => {
    const initializeFirebase = async () => {
      await requestPermission(handleFCMToken);
    };
    initializeFirebase();
  }, []);

  useEffect(() => {
    if (storage.getStorage("token") != null && fcmToken) {
      dispatch(deviceTokenRequest(fcmToken));
      storage.setStorage("fcmToken", fcmToken);
    }
  }, [fcmToken, dispatch]);

  const handleModal = () => {
    setopenModal(true);
    dispatch(getPopularPlanRequest({cityId:cityId}));
    dispatch(getPopularTestRequest(8));
    dispatch(getPopularPackageRequest(8));
    dispatch(viewHomeScreenProductRequest());
  };

  const handleInputModal = (event) => {
    if (event.target.value.length >= 3) {
      setopenModal(false);
      setOpenSearchModal(true);
      dispatch(elasticSearchRequest(event.target.value));
    } else {
      setopenModal(true);
      setOpenSearchModal(false);
    }
  };

  const handleClickOutside = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setopenModal(false);
      setOpenSearchModal(false);
      document.getElementById("search").value = "";
    }
  };

  const handleCityChange = (event) => {
    storage.setStorage("cityId", event.target.value);
    setCityId(event.target.value);
    dispatch(getPopularPlanRequest({cityId:event.target.value}));
    dispatch(getPopularPlanHomeScreenRequest({cityId:event.target.value}));
    dispatch(getZoneRequest(event.target.value));
  };

  const handleNavigationPopState = () => {
    setopenModal(false);
    setOpenSearchModal(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.value.length >= 3) {
      window.location.href = "/Search?item=false&result=" + event.target.value;
    }
  };

  const handleCartClick = (event) => {
    const itemCount = badgeCount > 0 ? badgeCount : 0;
    if (itemCount === 0) {
      setCartIsEmpty(true);
      setAnchorEl(event.currentTarget);
    } else {
      window.location.href = "/cart";
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAns = () => {
    setAns(!ans);
  };

  const handleFCMToken = (token) => setFcmtoken(token);

  const handleLogout = (bool) => {
    const secretKey = process.env.REACT_APP_SECURITY_KEY;
    const refreshToken = storage.getStorage("sessionToken");
    const decryptedBytes = CryptoJS.AES.decrypt(refreshToken, secretKey);
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
    dispatch(logoutUserRequest(fcmToken, decryptedData, bool));
    storage.removeStorage("sessionToken");
    storage.removeStorage("token");
    storage.removeStorage("name");
    storage.removeStorage("authorization");
    storage.removeStorage("id");
    storage.removeStorage("profileUpdated");
    window.location.reload();
  };

  return (
    <div>
      <div className="navbarWeb">
        <NavbarWeb
          cityNames={viewCityNames.data?.data.data.data}
          storage={storage}
          handleLogout={handleLogout}
          modalRef={modalRef}
          handleModal={handleModal}
          handleKeyPress={handleKeyPress}
          openSearchModal={openSearchModal}
          handleCartClick={handleCartClick}
          cartIsEmpty={cartIsEmpty}
          badgeCount={badgeCount}
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleInputModal={handleInputModal}
          setopenModal={setopenModal}
          openmodal={openmodal}
          name={name}
          inputRef={inputRef}
          handleCityChange={handleCityChange}
          cityId={cityId}
          zoneId = {getZone}
        />
      </div>
      <div className="navbarMobile">
        <NavbarMobile
          cityNames={viewCityNames.data?.data.data.data}
          storage={storage}
          handleLogout={handleLogout}
          modalRef={modalRef}
          handleModal={handleModal}
          handleKeyPress={handleKeyPress}
          openSearchModal={openSearchModal}
          handleCartClick={handleCartClick}
          cartIsEmpty={cartIsEmpty}
          badgeCount={badgeCount}
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleInputModal={handleInputModal}
          setopenModal={setopenModal}
          openmodal={openmodal}
          name={name}
          ans={ans}
          handleAns={handleAns}
          setAns={setAns}
          setOpenSearchModal={setOpenSearchModal}
          handleCityChange={handleCityChange}
        />
      </div>
    </div>
  );
}