import * as types from "../../Config/City/cityActionType"

export const getZoneRequest = (cityId) => {    
    return {
        type: types.GET_ZONE_REQUEST,
        payload:{cityId}
    }
}

export const getZoneSuccess = (data) => {
    return {
        type: types.GET_ZONE_SUCCESS,
        data: data,
    }
}

export const getZoneFailure = (data) => {
    return {
        type: types.GET_ZONE_FAILURE,
        data: data,
    }
}